@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
body {
  background: url(/static/media/main-bg.63121e6b.jpg);
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  padding: 5% 0 0 0;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 20px;
  max-width: 700px;
  text-align: center;
}

.title {
  font-size: 34px;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 10px;
  margin-top: 20px;
  text-shadow: 1px 1px #000;
}

.subtitle {
  font-size: 20px;
  font-weight: 600;
  text-shadow: 1px 1px #000000;
}

.searchSubTitle {
  font-size: 24px;
  font-weight: 600;
  text-shadow: 1px 1px #000000;
}


.product {
  color: black;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.product .name {
  background: #fff;
  opacity: 0.9;
  padding: 10px;
  text-align: right;
}

.product .description {
  background: #fff;
  opacity: 0.9;
  padding: 10px;
  text-align: left;
}

.header,
.search,
.howToOrder,
.viewCatalog,
.footer,
.result
{
  width: 100%;
}

.search {
  margin-bottom: 40px;
}

input {
  background-color: #fff;
  background-image: none;
  border: none;
  box-sizing: border-box;
  color: #555;
  font-size: 14px;
  height: 48px;
  line-height: 1.428571429;
  padding: 6px 12px;
  width: 100%;
}

button {
  background-color: #00432c;
  border: 1px solid transparent;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  padding: 10px 20px;
  transition: background 0.3s;
  width: 100%;
}

p {
  font-size: 16px;
  text-shadow: 1px 1px #000000;
}

iframe {
  width: 100%;
}

.footer {
  margin: 30px 0;
}

@media (min-width: 768px) {
  .App {
    margin: auto;
  }

  input {
    height: 48px;
    margin-right: 10px;
    width: 60%;
  }

  button {
    height: 48px;
    width: 35%;
  }

  ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
  }
}
